*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    display: flex;
    flex-direction: column;
}

body {
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #121212;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36.4px;
    color: black;
}

h4 {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    color: black;
}

.body-semi {
    color: #9b9b9b;
}

#root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

/* via https://google-webfonts-helper.herokuapp.com/fonts/ibm-plex-sans?subsets=latin */

/* ibm-plex-sans-regular - latin */
@font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/ibm-plex-sans-v9-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""), url("./assets/fonts/ibm-plex-sans-v9-latin-regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("./assets/fonts/ibm-plex-sans-v9-latin-regular.woff2") format("woff2"),
        /* Super Modern Browsers */ url("./assets/fonts/ibm-plex-sans-v9-latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("./assets/fonts/ibm-plex-sans-v9-latin-regular.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("./assets/fonts/ibm-plex-sans-v9-latin-regular.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-500 - latin */
@font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    src: url("./assets/fonts/ibm-plex-sans-v9-latin-500.eot"); /* IE9 Compat Modes */
    src: local(""), url("./assets/fonts/ibm-plex-sans-v9-latin-500.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("./assets/fonts/ibm-plex-sans-v9-latin-500.woff2") format("woff2"),
        /* Super Modern Browsers */ url("./assets/fonts/ibm-plex-sans-v9-latin-500.woff") format("woff"),
        /* Modern Browsers */ url("./assets/fonts/ibm-plex-sans-v9-latin-500.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("./assets/fonts/ibm-plex-sans-v9-latin-500.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-600 - latin */
@font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 600;
    src: url("./assets/fonts/ibm-plex-sans-v9-latin-600.eot"); /* IE9 Compat Modes */
    src: local(""), url("./assets/fonts/ibm-plex-sans-v9-latin-600.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("./assets/fonts/ibm-plex-sans-v9-latin-600.woff2") format("woff2"),
        /* Super Modern Browsers */ url("./assets/fonts/ibm-plex-sans-v9-latin-600.woff") format("woff"),
        /* Modern Browsers */ url("./assets/fonts/ibm-plex-sans-v9-latin-600.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("./assets/fonts/ibm-plex-sans-v9-latin-600.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}
/* ibm-plex-sans-700 - latin */
@font-face {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 700;
    src: url("./assets/fonts/ibm-plex-sans-v9-latin-700.eot"); /* IE9 Compat Modes */
    src: local(""), url("./assets/fonts/ibm-plex-sans-v9-latin-700.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("./assets/fonts/ibm-plex-sans-v9-latin-700.woff2") format("woff2"),
        /* Super Modern Browsers */ url("./assets/fonts/ibm-plex-sans-v9-latin-700.woff") format("woff"),
        /* Modern Browsers */ url("./assets/fonts/ibm-plex-sans-v9-latin-700.ttf") format("truetype"),
        /* Safari, Android, iOS */ url("./assets/fonts/ibm-plex-sans-v9-latin-700.svg#IBMPlexSans") format("svg"); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/roboto-v29-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/roboto-v29-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/roboto-v29-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-500 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('./assets/fonts/roboto-v29-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/roboto-v29-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/roboto-v29-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/roboto-v29-latin-500.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/roboto-v29-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/roboto-v29-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-700 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/roboto-v29-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./assets/fonts/roboto-v29-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./assets/fonts/roboto-v29-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./assets/fonts/roboto-v29-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./assets/fonts/roboto-v29-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./assets/fonts/roboto-v29-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }
